import React, { useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import moment from "moment-timezone";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import withStyles from '@mui/styles/withStyles';
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { DateRange, Event, History, Schedule, VisibilityOff } from "@mui/icons-material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowRight from "@mui/icons-material/ArrowRight";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
    BanReasonsLabel,
    JobStatusesLabel,
    JobStatusesMap,
    SuspensionDurationOptions,
    TimeframeLabel,
    TimeframeMap,
} from "helpers/constants/index";
// import { TextField } from "formik-mui";
import { filter, orderBy } from "lodash";
import _debounce from "lodash/debounce";
import _moment from "moment";
import PdfDialog
    from "views/Job/JobDetails/components/JobPositions/components/PositionTable/components/Dialogs/PayStub/PdfDialog/PdfDialog";
import ResendStubDialog
    from "views/Job/JobDetails/components/JobPositions/components/PositionTable/components/Dialogs/ResendStubDialog";
import EmployerAutocomplete from "components/Autocomplete/EmployerAutocomplete";
import Button from "components/Button/Button";
import MainContent from "components/Containers/MainContent";
import Dialog from "components/Dialog/Dialog";
import ViewPayrollDialog from "components/Dialog/Dialog";
import { DcTransferStatusBadge } from "components/Payments/DcBadge";
import TagsInput from "components/TagsInput";
import {
    convertUTCToLocal,
    convertUTCToTimezone,
    convertUTCToTimezoneWithOffset,
    formatDurationFromMins,
} from "utils/time";
import { actions as FinanceActions } from "store/modules/finance";
import { actions as WorkerDetailActions, selectors as WorkerDetailSelectors } from "store/modules/workerDetails";
import { actions as WorkerActions } from "store/modules/workers";
import EditWorkerDialog from "./components/EditWorkerDialog/EditWorkerDialog";
import {
    CustomTableCell,
    CustomTableRow,
    DashIcon,
    formatMoney,
    formatPhone,
    getDeductionType,
    getEarningType,
    statuses,
} from "./components/helpers";
import WorkerActionMenu from "./components/WorkerActionMenu";
import WorkerFinanceCard from "./components/WorkerFinanceCard";
import WorkerProfileCard from "./components/WorkerProfileCard";
import WorkerStatsCard from "./components/WorkerStatsCard";
import HistoryActionMenu from "./HistoryActionMenu";
import ReactTableCustom from "components/ReactTable";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import classNames from "classnames";

const styles = ({ spacing, shape, palette, customPalette }) => ({
    tabbedTableContainer: {
        marginTop: spacing(3),
    },
    tableContainer: {
        width: "100%",
    },
    tabIndicator: {
        display: "none !important",
    },
    tabRoot: {
        marginRight: spacing(1),
        borderRadius: `${shape.borderRadius}px`,
        opacity: 1,
        "&:hover": {
            border: `1px solid ${palette.secondary.main}`,
        },
    },
    tabLabelContainer: {
        padding: 0,
    },
    tabLabel: {
        fontSize: "1rem",
    },
    tabNav: {
        colorSecondary: {
            color: "#FFF",
        },
        boxShadow: "none",
        root: {
            "& > *": {
                margin: spacing(0),
                padding: spacing(0),
                width: "100%",
                // height: 400,
                boxShadow: "none",
            },
            boxShadow: "none",
        },
    },
    tabSelected: {
        border: `2px solid ${palette.secondary.main}`,
        backgroundColor: customPalette.primary[200],
        "&:hover": {
            border: `2px solid ${palette.secondary.main}`,
        },
    },
    documents: {
        color: palette.secondary.main,
        fontSize: "1rem",
    },
    answerStyle: {
        padding: spacing(3),
        backgroundColor: "#f8f9fa",
    },
    subtableHead: {
        backgroundColor: "#e1e8ec",
        height: 24,
        fontSize: 12,
        color: "#404b5a",
    },
    subtableCell: {
        border: "none",
    },
    table: {
        "&.table tbody .data-row td": {
            padding: spacing(1.5)
        }
    }
});

const tableProps = {
    manual: false,
    minRows: 0,
    resizable: false,
    showPageSizeOptions: true,
    showPageJump: true,
    showPaginationTop: false,
    showPaginationBottom: true,
    breakLengthFilter: "all", // pageSize: 20, // page: 0,
    defaultPageSize: 5,
    filterable: false,
    disableSortBy: true,
    rowsPerPageOptions: { rowsPerPageOptions: [5, 10, 20, 25, 50, 100] }
    // defaultFilterMethod: (filter, row) => String(row[filter.id]) === filter.value,
};

const TableWrapper = ({ onPageChange, onSortedChange, onFilteredChange, ...props }) => {
    const dispatch = useDispatch();
    const fetchData = useCallback(({ pageIndex, filters, sortBy }) => {
        onSortedChange && dispatch(() => onSortedChange(sortBy));
        onFilteredChange && dispatch(() => onFilteredChange(filters));
        onPageChange && dispatch(() => onPageChange(pageIndex));
    }, []);

    return (
        <ReactTableCustom
            fetchData={fetchData}
            rowsPerPageOptions={{ rowsPerPageOptions: [5] }}
            hasFilter={false}
            {...props}
        />
    );
}

class WorkerDetail extends React.Component {
    state = {
        value: 0,
        tabValue: "1",
        expanded: {},
        canEdit: false,
        isResendingStub: false,
        canSubmitUpdateWorker: true,
        showSuspendWorker: false,
        suspensionDuration: 3,
        showRemoveSuspension: false,
        showVerifyWorker: false,
        showUnverifyWorker: false,
        showActivateWorker: false,
        showAddWorkerNote: false,
        showAddTags: false,
        showDeleteAccount: false,
        showDeactivateWorker: false,
        employerSelected: null,
        positionId: null,
        banWorkerReason: null,
        showPreferWorker: false,
        showBanWorker: false,
        showEditWorker: false,
        suspensionReason: "",
        unsuspensionReason: "",
        verifyWorkerReason: "",
        unverifyWorkerReason: "",
        activateWorkerReason: "",
        deactivateWorkerReason: "",
        position: {},
        suspensionReasonLength: 250,
        isViewingPdf: false,
    };

    constructor() {
        super();
        this.workerCardRef = React.createRef();
        this.child = React.createRef();
    }

    componentDidMount() {
        this.props.fetchWorker();
        // this.props.loadWorkerNotes();
        // this.props.loadWorkerBannedFrom();

        this.props.dcCustomerNumber &&
            this.setState({
                isResendingStub: false,
                isViewingPdf: false,
                canEdit: false,
                showPayrollDialog: false,
                position: {},
            });
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    onExpandedChange = (expanded, index) => {
        const { job, deprecated_payroll } = this.props.positions[index];
        if (job.status === JobStatusesMap.Completed && deprecated_payroll.length) {
            return this.setState({ expanded });
        }
        return;
    };

    showEditWorker = () => {
        this.setState({
            showEditWorker: true,
        });
    };

    hideEditWorker = () => {
        this.setState({
            showEditWorker: false,
        });
    };

    showSuspendWorker = () => {
        this.setState({
            showSuspendWorker: true,
            suspensionDuration: 3,
        });
    };

    hideSuspendWorker = () => {
        this.setState({
            showSuspendWorker: false,
            suspensionReason: "",
        });
    };

    onSuspensionChange = event => {
        this.setState({
            suspensionDuration: event.target.value,
            suspensionReasonLength: event.target.value.length,
        });
    };

    handleSuspendWorker = () => {
        const { suspensionDuration, suspensionReason } = this.state;
        this.setState(
            {
                showSuspendWorker: false,
            },
            () => this.props.onSuspendWorker(suspensionDuration, suspensionReason)
        );
    };

    showRemoveSuspension = () => {
        this.setState({
            showRemoveSuspension: true,
            unsuspensionReason: "",
        });
    };

    hideRemoveSuspension = () => {
        this.setState({
            showRemoveSuspension: false,
            unsuspensionReason: "",
        });
    };

    handleRemoveSuspension = () => {
        const { unsuspensionReason } = this.state;
        this.setState(
            {
                showRemoveSuspension: false,
                unsuspensionReason: "",
            },
            () => this.props.onRemoveWorkerSuspension(unsuspensionReason)
        );
    };

    showVerifyWorker = () => {
        this.setState({
            showVerifyWorker: true,
            verifyWorkerReason: "",
        });
    };

    hideVerifyWorker = () => {
        this.setState({
            showVerifyWorker: false,
            verifyWorkerReason: "",
        });
    };

    handleVerifyWorker = () => {
        const { verifyWorkerReason } = this.state;
        this.setState(
            {
                showVerifyWorker: false,
            },
            () => this.props.onVerifyWorker(verifyWorkerReason)
        );
    };

    showUnverifyWorker = () => {
        this.setState({
            showUnverifyWorker: true,
            unverifyWorkerReason: "",
        });
    };

    hideUnverifyWorker = () => {
        this.setState({
            showUnverifyWorker: false,
            unverifyWorkerReason: "",
        });
    };

    handleUnverifyWorker = () => {
        const { unverifyWorkerReason } = this.state;

        this.setState(
            {
                showUnverifyWorker: false,
            },
            () => this.props.onUnverifyWorker(unverifyWorkerReason)
        );
    };

    showActivateWorker = () => {
        this.setState({
            showActivateWorker: true,
            activateWorkerReason: "",
        });
    };

    hideActivateWorker = () => {
        this.setState({
            showActivateWorker: false,
            activateWorkerReason: "",
        });
    };

    handleActivateWorker = () => {
        const { activateWorkerReason } = this.state;
        this.setState(
            {
                showActivateWorker: false,
            },
            () => this.props.onActivateWorker(activateWorkerReason)
        );
    };

    showDeactivateWorker = () => {
        this.setState({
            showDeactivateWorker: true,
            deactivateWorkerReason: "",
        });
    };

    hideDeactivateWorker = () => {
        this.setState({
            showDeactivateWorker: false,
            deactivateWorkerReason: "",
        });
    };

    handleDeactivateWorker = () => {
        const { deactivateWorkerReason } = this.state;
        this.setState(
            {
                showDeactivateWorker: false,
                deactivateWorkerReason: "",
            },
            () => this.props.onDeactivateWorker(deactivateWorkerReason)
        );
    };

    onEmployerChange = employer => {
        this.setState({
            employerSelected: employer,
        });
    };

    showPreferWorker = () => {
        this.setState({
            showPreferWorker: true,
            employerSelected: null,
        });
    };

    hidePreferWorker = () => {
        this.setState({
            showPreferWorker: false,
        });
    };

    handlePreferWorker = () => {
        const { data } = this.state.employerSelected;
        this.setState(
            {
                showPreferWorker: false,
                employerSelected: null,
            },
            () => this.props.onPreferWorker(data.id, data.companyName)
        );
    };

    showBanWorker = () => {
        this.setState({
            showBanWorker: true,
            employerSelected: null,
            banWorkerReason: "",
        });
    };

    hideBanWorker = () => {
        this.setState({
            showBanWorker: false,
            banWorkerReason: "",
        });
    };

    handleBanWorker = () => {
        const { banWorkerReason, employerSelected } = this.state;
        const { data } = employerSelected;
        this.setState(
            {
                showBanWorker: false,
            },
            () => this.props.onBanWorker(data.id, data.companyName, banWorkerReason)
        );
    };

    showAddWorkerNote = () => {
        this.setState({
            showAddWorkerNote: true,
        });
    };

    hideAddWorkerNote = () => {
        this.setState(
            {
                showAddWorkerNote: false,
            },
            () => this.props.resetWorkerNote()
        );
    };

    handleAddWorkerNote = () => {
        this.setState(
            {
                showAddWorkerNote: false,
            },
            () => this.props.onAddWorkerNote()
        );
    };

    onAddWorkerNoteChange = data => {
        this.props.updateWorkerNote(data.target.value);
    };

    showAddTags = () => {
        this.setState({
            showAddTags: true,
        });
    };

    hideAddTags = () => {
        this.setState(
            {
                showAddTags: false,
            },
            () => this.props.resetWorkerTags()
        );
    };

    handleAddTags = () => {
        this.setState(
            {
                showAddTags: false,
            },
            () => this.props.onAddWorkerTags()
        );
    };

    onAddTagsChange = data => {
        this.props.updateWorkerTags(data);
    };

    handleRemoveWorkerTags = (data) => {
        if (data) {
            this.props.removeWorkerTags(data)
        }
    }

    showDeleteAccount = () => {
        this.setState({
            showDeleteAccount: true,
        });
    };

    hideDeleteAccount = () => {
        this.setState(
            {
                showDeleteAccount: false,
            }
        );
    };

    handleDeleteAccount = () => {
        this.setState(
            {
                showDeleteAccount: false,
            }
        );
        this.props.onDeleteWorkerAccount().then(res => {
            if (res) {
                this.props.history.push(`/workers/overview?workerframe=0&listType=0`);
            }
        })
    };

    handleWorkerTagClick = (data) => {
        this.props.changeFilterTags([{ id: "tags", value: [data] }], 'workerList');
        this.props.history.push(`/workers/overview?workerframe=0&listType=0`);
    }

    onBanWorkerReasonChange = event => {
        this.setState({
            banWorkerReason: event.target.value,
        });
    };

    onSuspensionReasonChange = event => {
        this.setState({
            suspensionReason: event.target.value,
        });
    };

    onUnsuspensionReasonChange = event => {
        this.setState({
            unsuspensionReason: event.target.value,
        });
    };

    onActivateReasonChange = event => {
        this.setState({
            activateWorkerReason: event.target.value,
        });
    };

    onDeactivateReasonChange = event => {
        this.setState({
            deactivateWorkerReason: event.target.value,
        });
    };

    onVerifyWorkerReasonChange = event => {
        this.setState({
            verifyWorkerReason: event.target.value,
        });
    };
    onUnverifyWorkerReasonChange = event => {
        this.setState({
            unverifyWorkerReason: event.target.value,
        });
    };

    renderEmployerSelected = () => {
        return (
            <React.Fragment>
                <EmployerAutocomplete
                    style={{ marginTop: 36, marginBottom: 24 }}
                    value={this.state.employerSelected}
                    onChange={this.onEmployerChange}
                />
                {this.state.employerSelected && (
                    <React.Fragment>
                        <Typography variant="h5">{this.state.employerSelected.data.companyName}</Typography>
                        <Typography variant="body1">
                            {`${this.state.employerSelected.data.street}, ${this.state.employerSelected.data.city}, ${this.state.employerSelected.data.region}`}
                        </Typography>
                        <Typography variant="body1">
                            {`${this.state.employerSelected.data.firstName} ${this.state.employerSelected.data.lastName}`}
                        </Typography>
                        <Typography variant="body1">{this.state.employerSelected.data.email}</Typography>
                        <Typography variant="body1">
                            {formatPhone(this.state.employerSelected.data.mobilePhone)}
                        </Typography>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    renderEdit = () => {
        if (!this.state.showEditWorker) {
            return null;
        }

        const profileCard = this.workerCardRef.current.getBoundingClientRect();
        const editContainerStyle = {
            backgroundColor: "#FFF",
            border: `1px solid #CCC`,
            borderRadius: 4,
            padding: `24px !important`,
            height: profileCard.height,
        };

        return (
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1100,
                    width: "100%",
                    height: "100%",
                    padding: "8px 32px 15px 32px",
                }}>
                <Grid container justifyContent="flex-end">
                    <Button label="Cancel" onClick={() => { }} color="transparent" />
                    <Button label="Save Changes" onClick={() => { }} color="secondary" />
                </Grid>
                <Grid container style={{ marginTop: 8 }}>
                    <Grid item xs={6} style={{ paddingRight: 16 }}>
                        <Grid container item xs={12} style={editContainerStyle}>
                            <span>Test</span>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    };

    updateFormData = event => {
        this.props.setUpdateWorkerData(event.target.id, event.target.value);
    };
    updateSelectFormData = ({ target: { name, value } }) => {
        let val = value;

        this.props.setUpdateWorkerData(name, val);
    };
    handleSetCanEdit = () => {
        this.setState({ canEdit: !this.state.canEdit });
        this.child.current.handleClose();
    };

    handleToggleChange = ({ target: { name, checked } }) =>
        this.props.setUpdateWorkerData(name, checked);

    handleUpdateWorkerProfile = () => {
        this.props.updateWorkerProfile();
        this.handleSetCanEdit(false);
        this.props.clearWorkerUpdateData();
    };
    handleCancelUpdateWorkerProfile = () => {
        this.handleSetCanEdit(false);
        this.props.clearWorkerUpdateData();
    };

    handleSetCanSubmit = val => this.setState({ canSubmitUpdateWorker: val });

    handleFindTransfers = () => {
        const payload = {
            //   type: searchType === "All" ? "" : searchType || null,
            type: "",

            customerNumber: this.props.worker.dcCustomerNumber || null,
            //   maxDate: searchMaxDate ? moment().add(searchMaxDate, "days") : null || null,
            //   maxExpiryDate: searchMaxExpiryDate ? moment().add(searchMaxExpiryDate, "days") : null || null,
            //   minDate: searchMinDate ? moment().add(searchMinDate, "days") : null || null,
            //   minExpiryDate: searchMinExpiryDate ? moment().add(searchMinExpiryDate, "days") : null || null,
            //   transactionId: searchTransactionId || null,
        };
        this.props.findWorkerEtransfers(payload);
    };

    handleShowPayrollDialog = position => {
        this.setState({
            showPayrollDialog: true,
            employerSelected: null,
            banWorkerReason: "",
            position,
        });
    };

    toggleIsViewingPdf = position => {
        this.setState({ position: position[0], isViewingPdf: true });
    };

    handleTabChange = (event, value) => {
        this.setState({ tabValue: value });
    };
    handleResendStub = position => {
        // toggleAddBonus();
        this.props.createAndSendPaystub(position);
        this.setState({ isResendingStub: !this.state.isResendingStub });
    };
    toggleResendStubDialog = position => {
        this.setState({
            position,
            positionId: position.positionId,
            isResendingStub: !this.state.isResendingStub,
        });
    };
    render() {
        const {
            classes,
            fullName,
            signedProfileImageUrl,
            vaccinationStatus,
            firstName,
            lastName,
            sin,
            phone,
            email,
            street,
            city,
            region,
            country,
            postalCode,
            rating,
            positionCount,
            preferCount,
            noShowCount,
            banCount,
            totalHoursWorked,
            grossPay,
            totalDeductions,
            netPay,
            isSuspended,
            suspensionStartUtc,
            suspensionEndUtc,
            isActive,
            isApproved,
            positions,
            isLoading,
            isApplyingAction,
            onPageChangePositions,
            onSortedChangePositions,
            onFilteredChangePositions,
            positionsPaginationData,
            availability,
            createdAt,
            approvedAt,
            workerNote,
            workerNotes,
            onPageChangeWorkerNotes,
            workerNotesPaginationData,
            workerBannedFrom,
            workerBannedFromPaginationData,
            fetchWorkerBannedFrom,
            worker,
            createdAtUtc,
            approvedAtUtc,
            workerDCTRansactionList,
            isDCTransactionLoading,
            tags,
            workerTag
        } = this.props;
        const { position, showEditWorker, isViewingPdf, isResendingStub, positionId } = this.state;
        const tabClass = {
            root: classes.tabRoot,
            // labelContainer: classes.tabLabelContainer,
            // label: classes.tabLabel,
            selected: classes.tabSelected,
        };
        let deductionsTotal = 0;
        let contributionsTotal = 0;
        let earningsTotal = 0;
        const payroll = position && position.payrolls ? orderBy(position.payrolls, "id", "desc") : [];
        const payment = payroll.length ? payroll[0] : null;

        return (
            <MainContent
                isLoading={isLoading}
                isApplyingAction={isApplyingAction}
                noTopPadding
                disabled={showEditWorker}>
                {this.renderEdit()}
                <PdfDialog
                    open={isViewingPdf}
                    onClose={this.toggleIsViewingPdf}
                    position={position}
                    payroll={payment}
                    // jobDetails={jobDetails}
                    handleOpen={() => this.toggleIsViewingPdf()}
                    handleClose={() => this.toggleIsViewingPdf()}
                />
                {position && positionId && (
                    <ResendStubDialog
                        open={isResendingStub}
                        onClose={this.toggleResendStubDialog}
                        onCancel={this.toggleResendStubDialog}
                        onConfirm={() => this.handleResendStub(positionId)}
                        position={position}
                    />
                )}
                {payment && (
                    <ViewPayrollDialog
                        open={this.state.showPayrollDialog}
                        title={`Payroll Details`}
                        confirmText="Suspend"
                        onClose={this.handleShowPayrollDialog}
                        onConfirm={this.handleShowPayrollDialog}
                        onCancel={this.handleShowPayrollDialog}
                        alertType="form"
                        icon="event_busy"
                        hideActions
                        size="md"
                        fullWidth
                        disabled={false}>
                        <Grid container item xs={12} style={{ paddingLeft: 0, paddingRight: 0, maxHeight: '450px', overflow: 'auto' }}>
                            <TabContext value={this.state.tabValue}>
                                <AppBar position="static" color="transparent" className={classes.tabNav}>
                                    <TabList onChange={this.handleTabChange} aria-label="simple tabs example">
                                        <Tab label="Payroll Summary" value="1" />
                                        <Tab label="Payment History" value="2" />
                                    </TabList>
                                </AppBar>
                                <TabPanel value="1" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                    <Grid
                                        container
                                        alignContent="flex-start"
                                        alignItems="flex-start"
                                        item
                                        xs={12}
                                        style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <Grid container item xs={12} style={{ paddingBottom: 4 }}>
                                            <Table size="small">
                                                <TableHead>
                                                    <CustomTableRow>
                                                        <CustomTableCell>Earnings</CustomTableCell>
                                                        <CustomTableCell>Units</CustomTableCell>
                                                        <CustomTableCell>Rate</CustomTableCell>
                                                        <CustomTableCell style={{ textAlign: "right" }}>Amount</CustomTableCell>
                                                    </CustomTableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {payment.earnings.map((earning, i) => {
                                                        const earningType = getEarningType(earning.payrollEarningsTypeId);
                                                        earningsTotal = earningsTotal + earning.amount;
                                                        return (
                                                            <CustomTableRow key={i}>
                                                                <CustomTableCell>
                                                                    {(earningType && earningType.name) || <DashIcon />}
                                                                </CustomTableCell>
                                                                <CustomTableCell>{earning.units || <DashIcon />}</CustomTableCell>
                                                                <CustomTableCell>
                                                                    {formatMoney(earning.rate) || <DashIcon />}
                                                                </CustomTableCell>
                                                                <CustomTableCell style={{ textAlign: "right" }}>
                                                                    {formatMoney(earning.amount) || <DashIcon />}
                                                                </CustomTableCell>
                                                            </CustomTableRow>
                                                        );
                                                    })}
                                                    <CustomTableRow>
                                                        <CustomTableCell colSpan={3}>Total</CustomTableCell>
                                                        <CustomTableCell style={{ textAlign: "right" }}>
                                                            {formatMoney(earningsTotal)}
                                                        </CustomTableCell>
                                                    </CustomTableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                        <Grid container item xs={12} md={6} style={{ paddingBottom: 4 }}>
                                            <Table size="small" style={{ marginTop: 16 }}>
                                                <TableHead>
                                                    <CustomTableRow>
                                                        <CustomTableCell>Deductions</CustomTableCell>
                                                        <CustomTableCell colSpan={3} style={{ textAlign: "right" }}>
                                                            Amount
                                                        </CustomTableCell>
                                                    </CustomTableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {payment.employeeDeductions.map((deduction, i) => {
                                                        const deductionType = getDeductionType(
                                                            deduction.payrollDeductionsTypeId
                                                        );

                                                        if (deductionType && deductionType.code === "EE")
                                                            deductionsTotal = deductionsTotal + deduction.amount;
                                                        return (
                                                            <CustomTableRow key={i}>
                                                                <CustomTableCell>
                                                                    {(deductionType && deductionType.longName) || <DashIcon />}
                                                                </CustomTableCell>
                                                                <CustomTableCell colSpan={3} style={{ textAlign: "right" }}>
                                                                    {formatMoney(deduction.amount)}
                                                                </CustomTableCell>
                                                            </CustomTableRow>
                                                        );
                                                    })}
                                                    <CustomTableRow>
                                                        <CustomTableCell colSpan={3}>Total</CustomTableCell>
                                                        <CustomTableCell style={{ textAlign: "right" }}>
                                                            {formatMoney(deductionsTotal)}
                                                        </CustomTableCell>
                                                    </CustomTableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                        <Grid container item xs={12} md={6} style={{ paddingBottom: 4 }}>
                                            <Table size="small" style={{ marginTop: 16 }}>
                                                <TableHead>
                                                    <CustomTableRow>
                                                        <CustomTableCell colSpan={3}>Employer Contributions</CustomTableCell>
                                                        <CustomTableCell style={{ textAlign: "right" }}>Amount</CustomTableCell>
                                                    </CustomTableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {payment.employerDeductions.map((deduction, i) => {
                                                        const deductionType = getDeductionType(
                                                            deduction.payrollDeductionsTypeId
                                                        );
                                                        if (deductionType && deductionType.code === "ER")
                                                            contributionsTotal = contributionsTotal + deduction.amount;

                                                        return (
                                                            <CustomTableRow key={i}>
                                                                <CustomTableCell colSpan={3}>
                                                                    {(deductionType && deductionType.longName) || <DashIcon />}
                                                                </CustomTableCell>
                                                                <CustomTableCell style={{ textAlign: "right" }}>
                                                                    {formatMoney(deduction.amount)}
                                                                </CustomTableCell>
                                                            </CustomTableRow>
                                                        );
                                                    })}
                                                    <CustomTableRow>
                                                        <CustomTableCell colSpan={3}>Total</CustomTableCell>
                                                        <CustomTableCell style={{ textAlign: "right" }}>
                                                            {formatMoney(contributionsTotal)}
                                                        </CustomTableCell>
                                                    </CustomTableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="2" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                    <Grid
                                        container
                                        alignContent="flex-start"
                                        alignItems="flex-start"
                                        item
                                        xs={12}
                                        style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <Grid container item xs={12} className={classes.tableContainer}>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                style={{ paddingLeft: 8, paddingBottom: 4, height: 50 }}>
                                                <Typography
                                                    variant="h6"
                                                    style={{ width: "100%" }}>{`Transfer History`}</Typography>
                                                <Typography
                                                    variant="caption"
                                                    style={{ width: "100%" }}>{`Provider: DC Bank`}</Typography>
                                            </Grid>
                                            <Grid container item xs={12} style={{ paddingBottom: 4 }}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <CustomTableRow>
                                                            <CustomTableCell>Payment Id</CustomTableCell>
                                                            <CustomTableCell>Position Id</CustomTableCell>
                                                            <CustomTableCell>Payroll Id</CustomTableCell>
                                                            <CustomTableCell>Transfer Id</CustomTableCell>
                                                            <CustomTableCell>Sent</CustomTableCell>
                                                            {/* <CustomTableCell>Payment Type</CustomTableCell> */}
                                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                                Amount
                                                            </CustomTableCell>
                                                            <CustomTableCell style={{ textAlign: "center", width: 60 }}>
                                                                Status
                                                            </CustomTableCell>
                                                            <CustomTableCell style={{ textAlign: "right" }} />
                                                        </CustomTableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {payroll &&
                                                            payroll.map((n, j) => {
                                                                if (!n.payments)
                                                                    return;
                                                                // const record = n.payments[0]
                                                                return (
                                                                    <React.Fragment key={j}>
                                                                        {n.payments.map((m, i) => {
                                                                            const code = statuses.find(c => c.id === m.paymentStatus.id);
                                                                            return (
                                                                                <CustomTableRow key={i}>
                                                                                    <CustomTableCell>{m.id}</CustomTableCell>
                                                                                    <CustomTableCell>{payment.positionId}</CustomTableCell>
                                                                                    <CustomTableCell>{m.payrollId}</CustomTableCell>
                                                                                    <CustomTableCell>{m.dcTransferId}</CustomTableCell>
                                                                                    <CustomTableCell>
                                                                                        {moment(m.sentAt).format("MMM Do, YYYY h:mm a z")}
                                                                                    </CustomTableCell>
                                                                                    {/* <CustomTableCell>
                                              {m.interacStatus && m.dcStatus
                                                ? "Adjustment"
                                                : "Payment"}
                                            </CustomTableCell> */}
                                                                                    <CustomTableCell style={{ textAlign: "right" }}>
                                                                                        {formatMoney(m.amount)}
                                                                                    </CustomTableCell>
                                                                                    <CustomTableCell style={{ textAlign: "center", width: 60 }}>
                                                                                        {code && (
                                                                                            <DcTransferStatusBadge code={code.dcCode} iconOnly />
                                                                                        )}
                                                                                    </CustomTableCell>
                                                                                    <CustomTableCell style={{ textAlign: "right" }}>
                                                                                        {/* <SubTransfersActions
                                    handleClickOpenCancelDialog={() => handleClickOpenCancelDialog(data)}
                                    handleClickOpenResendDialog={() => handleClickOpenResendDialog(data)}
                                    handleClickOpenResolveDialog={() =>
                                      handleClickOpenResolveDialog(m.id)
                                    }
                                    handleViewProfile={handleViewProfile}
                                    payment={data}
                                    transactionId={m.id}
                                  /> */}
                                                                                    </CustomTableCell>
                                                                                </CustomTableRow>
                                                                            );
                                                                        })
                                                                        }
                                                                    </React.Fragment>
                                                                )


                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </TabContext>
                        </Grid>
                    </ViewPayrollDialog>
                )}
                <EditWorkerDialog
                    open={this.state.canEdit}
                    title={`Manage Worker Settings`}
                    description={null}
                    confirmText="Update"
                    fullWidth
                    size="sm"
                    onClose={this.handleCancelUpdateWorkerProfile}
                    onConfirm={this.handleUpdateWorkerProfile}
                    onCancel={this.handleCancelUpdateWorkerProfile}
                    handleSetCanSubmit={this.handleSetCanSubmit}
                    handleSetCanEdit={this.handleSetCanEdit}
                />
                <Grid container justifyContent="flex-end">
                    <WorkerActionMenu
                        canSuspend={!isSuspended}
                        canRemoveSuspension={isSuspended}
                        canVerify={!isApproved}
                        canUnverify={isApproved}
                        canActivate={!isActive}
                        canDeactivate={isActive}
                        onEdit={this.showEditWorker}
                        onSuspend={this.showSuspendWorker}
                        onSuspensionRemove={this.showRemoveSuspension}
                        onVerify={this.showVerifyWorker}
                        onUnverify={this.showUnverifyWorker}
                        onActivate={this.showActivateWorker}
                        onDeactivate={this.showDeactivateWorker}
                        onPrefer={this.showPreferWorker}
                        onBan={this.showBanWorker}
                        onAddWorkerNote={this.showAddWorkerNote}
                        onAddTags={this.showAddTags}
                        onDeleteAccount={this.showDeleteAccount}
                        setCanEdit={this.handleSetCanEdit}
                        worker={worker}
                        ref={this.child}
                    />
                </Grid>
                <Grid container>
                    <>
                        <WorkerProfileCard
                            fullName={fullName}
                            signedProfileImageUrl={signedProfileImageUrl}
                            vaccinationStatus={vaccinationStatus}
                            firstName={firstName}
                            lastName={lastName}
                            sin={sin}
                            phone={phone}
                            email={email}
                            street={street}
                            city={city}
                            region={region}
                            country={country}
                            postalCode={postalCode}
                            isSuspended={isSuspended}
                            suspensionStartUtc={suspensionStartUtc}
                            suspensionEndUtc={suspensionEndUtc}
                            isActive={isActive}
                            isApproved={isApproved}
                            approvedAt={approvedAt}
                            createdAt={createdAt}
                            approvedAtUtc={approvedAtUtc}
                            createdAtUtc={createdAtUtc}
                            tags={tags}
                            handleRemoveWorkerTags={this.handleRemoveWorkerTags}
                            handleWorkerTagClick={this.handleWorkerTagClick}
                        />
                    </>
                    <WorkerStatsCard
                        rating={rating}
                        availability={availability}
                        positionCount={positionCount}
                        preferCount={preferCount}
                        noShowCount={noShowCount}
                        banCount={banCount}
                    />
                    <WorkerFinanceCard
                        totalHoursWorked={totalHoursWorked}
                        grossPay={grossPay}
                        totalDeductions={totalDeductions}
                        netPay={netPay}
                    />
                </Grid>
                <Grid
                    container
                    className={classes.tabbedTableContainer}
                    alignItems="center"
                    justifyContent="flex-end">
                    <Grid container item xs={12}>
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            classes={{ indicator: classes.tabIndicator }}>
                            <Tab label="Jobs" classes={tabClass} />
                            <Tab label="Notes" classes={tabClass} />
                            <Tab label="Banned From" classes={tabClass} />
                            <Tab label="DC Transactions" classes={tabClass} />
                        </Tabs>
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end">
                        {/* <Button
                            buttonClassName={classes.documents}
                            color="transparent"
                            label="Worker Documents"
                            icon={<ChevronRight />}
                            alignIcon="right"
                            onClick={() => {}}
                        /> */}
                    </Grid>
                </Grid>
                <Grid container item xs={12} className={classes.tabbedTableContainer}>
                    <SwipeableViews axis="x" index={this.state.value} className={classes.tableContainer}>
                        <TableWrapper
                            classes="-highlight"
                            data={positions}
                            onPageChange={onPageChangePositions}
                            onFilteredChange={onFilteredChangePositions}
                            onSortedChange={onSortedChangePositions}
                            pageCount={positionsPaginationData.totalCount}
                            currentPage={positionsPaginationData.currentPage}
                            loading={positionsPaginationData.isLoading}
                            defaultPageSize={positionsPaginationData.pageSize}
                            expandView={row => {
                                return (
                                    <div className={classes.answerStyle}>
                                        <Table size="small">
                                            <TableHead>
                                                <CustomTableRow>
                                                    <CustomTableCell>Transfer ID</CustomTableCell>
                                                    <CustomTableCell>Transfer Timestamp</CustomTableCell>
                                                    <CustomTableCell>Reason</CustomTableCell>
                                                    <CustomTableCell>Amount</CustomTableCell>
                                                    <CustomTableCell>Status</CustomTableCell>
                                                </CustomTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {row.original.deprecated_payroll.map(record => {
                                                    return (
                                                        <CustomTableRow key={record.dcTransferId}>
                                                            <CustomTableCell>{record.dcTransferId}</CustomTableCell>
                                                            <CustomTableCell>{record.completedAt}</CustomTableCell>
                                                            <CustomTableCell>
                                                                {record.adjustmentType && record.adjustedPayrollId
                                                                    ? "Adjustment"
                                                                    : "Payment"}
                                                            </CustomTableCell>
                                                            <CustomTableCell>{record.netPay}</CustomTableCell>
                                                            <CustomTableCell>...</CustomTableCell>
                                                        </CustomTableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </div>
                                );
                            }}
                            columns={[
                                {
                                    Header: "",
                                    width: 50,
                                    id: "timeframe",
                                    // eslint-disable-next-line react/display-name, react/prop-types
                                    accessor: ({ job }) => {
                                        let Icon;
                                        if (!job.isActive) {
                                            Icon = VisibilityOff;
                                        } else if (job.timeframe === TimeframeMap.Today) {
                                            Icon = Schedule;
                                        } else if (job.timeframe === TimeframeMap.Tomorrow) {
                                            Icon = Event;
                                        } else if (job.timeframe === TimeframeMap.Upcoming) {
                                            Icon = DateRange;
                                        } else if (job.timeframe === TimeframeMap.Past) {
                                            Icon = History;
                                        } else {
                                            return "Unknown timeframe";
                                        }

                                        return (
                                            <Tooltip title={TimeframeLabel[job.timeframe]} placement="top">
                                                <Icon color="action" />
                                            </Tooltip>
                                        );
                                    },
                                    disableSortBy: true,
                                    disableFilters: true,
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "ID",
                                    id: "jobId",
                                    disableFilters: true,
                                    disableSortBy: true,
                                    // eslint-disable-next-line react/display-name, react/prop-types
                                    accessor: ({ jobId }) => {
                                        return jobId;
                                    },
                                    className: "-cursor-pointer",
                                    width: 60,
                                },
                                {
                                    Header: "Job Date",
                                    id: "jobDate",
                                    disableSortBy: true,
                                    disableFilters: true,
                                    minWidth: 140,
                                    // eslint-disable-next-line react/display-name, react/prop-types
                                    accessor: ({ job }) => {
                                        if (!job.startUtc && !job.start) {
                                            return <DashIcon />;
                                        }
                                        return `${convertUTCToTimezone(
                                            job.startUtc || job.start,
                                            job.timezone,
                                            "MMM Do, YYYY h:mm a"
                                        )} - ${convertUTCToTimezoneWithOffset(
                                            job.endUtc || job.end,
                                            job.timezone,
                                            "h:mm a"
                                        )}`;
                                    },
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "Check in/out",
                                    id: "shiftStart",
                                    disableFilters: true,
                                    disableSortBy: true,
                                    minWidth: 140,
                                    // eslint-disable-next-line react/display-name, react/prop-types
                                    accessor: ({ startShiftUtc, endShiftUtc, job, startShift, endShift }) => {
                                        if (!startShiftUtc && !startShift) {
                                            return <DashIcon />;
                                        }
                                        return `${convertUTCToTimezone(
                                            startShiftUtc || startShift,
                                            job.timezone,
                                            "MMM Do, YYYY h:mm a"
                                        )} - ${convertUTCToTimezoneWithOffset(
                                            endShiftUtc || endShift,
                                            job.timezone,
                                            "h:mm a"
                                        )}`;
                                    },
                                    className: "-cursor-pointer",
                                },

                                {
                                    Header: "Company",
                                    id: "companyName",
                                    disableSortBy: true,
                                    disableFilters: true,
                                    // eslint-disable-next-line react/display-name, react/prop-types
                                    accessor: ({
                                        job: {
                                            employer: { companyName },
                                        },
                                    }) => {
                                        return companyName;
                                    },
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "Rating",
                                    id: "workerRating",
                                    disableFilters: true,
                                    disableSortBy: true,
                                    // eslint-disable-next-line react/display-name, react/prop-types
                                    accessor: ({ workerRating, defaultWorkerRating }) => {
                                        if (!workerRating && !defaultWorkerRating) {
                                            return <i>Not rated</i>;
                                        }

                                        if (!workerRating) {
                                            return (
                                                <Grid container alignItems="center">
                                                    <span>{defaultWorkerRating}</span>
                                                    <Typography variant="caption" style={{ marginLeft: 8 }}>
                                                        <i>(default)</i>
                                                    </Typography>
                                                </Grid>
                                            );
                                        }
                                        return workerRating;
                                    },
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "Duration",
                                    id: "durationMins",
                                    disableFilters: true,
                                    disableSortBy: true,
                                    // eslint-disable-next-line react/display-name, react/prop-types
                                    accessor: ({ durationMins }) => {
                                        return formatDurationFromMins(durationMins, {
                                            hourLabel: "hrs",
                                            minuteLabel: "mins",
                                        });
                                    },
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "Rate",
                                    id: "payRate",
                                    disableFilters: true,
                                    disableSortBy: true,
                                    // eslint-disable-next-line react/display-name, react/prop-types
                                    accessor: ({ payRate }) => {
                                        return formatMoney(payRate);
                                    },
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "Earned",
                                    id: "grossEarnings",
                                    disableFilters: true,
                                    disableSortBy: true,
                                    width: 100,
                                    // eslint-disable-next-line react/display-name, react/prop-types
                                    accessor: ({ deprecated_payroll, job, payrolls }) => {
                                        if (job.status !== JobStatusesMap.Completed) {
                                            return JobStatusesLabel[job.status];
                                        }

                                        if (!payrolls.length) {
                                            return "Pay Pending";
                                        }
                                        const payroll = filter(payrolls, (n) => {
                                            if (n.payments.length > 0) {
                                                return [5, 13].includes(n.payments[0].paymentStatusId)
                                            }
                                            return false;
                                        })
                                        return formatMoney(
                                            payroll.reduce((acc, { netEarnings }) => acc + netEarnings, 0)
                                        );
                                    },
                                    className: "-cursor-pointer",
                                },
                                {
                                    expander: true,
                                    width: 60,
                                    id: 'expander',
                                    Cell: ({ row: { isExpanded, original, ...row } }) => {
                                        const { job, deprecated_payroll } = original;
                                        if (job.status === JobStatusesMap.Completed && deprecated_payroll.length) {
                                            return isExpanded ? <ArrowDropDown  {...row.getToggleRowExpandedProps()} /> : <ArrowRight {...row.getToggleRowExpandedProps()} />;
                                        }
                                        return null;
                                    },
                                },
                                {
                                    disableFilters: true,
                                    disableSortBy: true,
                                    filterable: false,
                                    collapse: true,
                                    style: { textAlign: "right" },
                                    id: "actions",
                                    Cell: ({ row: { original } }) => {
                                        return (
                                            <HistoryActionMenu
                                                jobId={original.jobId}
                                                // positionId={original.payroll[0].positionId}
                                                position={original.payroll}
                                                job={original}
                                                handleShowPayrollDialog={this.handleShowPayrollDialog}
                                                // toggleIsViewingPdf={this.toggleIsViewingPdf}
                                                toggleResendStubDialog={this.toggleResendStubDialog}
                                                employerId={original.job.employerId}
                                            />
                                        );
                                    },
                                },
                            ]}
                        />
                        <TableWrapper
                            data={workerNotes}
                            classes={classNames("-highlight", classes.table)}
                            onPageChange={onPageChangeWorkerNotes}
                            loading={workerNotesPaginationData.isLoading}
                            pageCount={workerNotesPaginationData.totalCount}
                            currentPage={workerNotesPaginationData.currentPage}
                            defaultPageSize={workerNotesPaginationData.pageSize}
                            columns={[
                                {
                                    Header: "Date",
                                    id: "date",
                                    // eslint-disable-next-line react/display-name, react/prop-types
                                    accessor: ({ createdAtUtc }) => {
                                        const start = convertUTCToLocal(
                                            createdAtUtc,
                                            "MMM Do, YYYY  h:mm a z"
                                        ).replace(":00", "");

                                        return start;
                                    },
                                    className: "-cursor-pointer",
                                    width: 200,
                                    disableSortBy: true,
                                },
                                {
                                    Header: "Agent",
                                    id: "agent",
                                    // eslint-disable-next-line react/display-name, react/prop-types
                                    accessor: ({ agent }) => {
                                        return agent;
                                    },
                                    className: "-cursor-pointer",
                                    disableSortBy: true,
                                    width: 200,
                                },
                                {
                                    Header: "Action",
                                    id: "noteType",
                                    // eslint-disable-next-line react/display-name, react/prop-types
                                    accessor: ({ noteType }) => {
                                        if (noteType === "Suspend") {
                                            return "Suspended";
                                        }
                                        if (noteType === "Unsuspend") {
                                            return "Unsuspended";
                                        }
                                        if (noteType === "Deactivate") {
                                            return "Deactivated";
                                        }
                                        if (noteType === "Activate") {
                                            return "Activated";
                                        }
                                        if (noteType === "Unverify") {
                                            return "Unverified";
                                        }
                                        if (noteType === "Custom") {
                                            return "Note";
                                        }
                                        return noteType;
                                    },
                                    className: "-cursor-pointer",
                                    disableSortBy: true,
                                    width: 200,
                                },
                                {
                                    Header: "Notes",
                                    id: "note",
                                    // eslint-disable-next-line react/display-name, react/prop-types
                                    accessor: ({ note }) => {
                                        return note;
                                    },
                                    disableSortBy: true,
                                    className: "-cursor-pointer",
                                },
                            ]}
                        />
                        <TableWrapper
                            data={workerBannedFrom}
                            classes={classNames("-highlight", classes.table)}
                            onPageChange={(e) => fetchWorkerBannedFrom(e, this.props.match?.params?.id)}
                            loading={workerBannedFromPaginationData.isLoading}
                            pageCount={workerBannedFromPaginationData.totalCount}
                            currentPage={workerBannedFromPaginationData.currentPage}
                            defaultPageSize={workerBannedFromPaginationData.pageSize}
                            columns={[
                                {
                                    Header: "Company Name",
                                    id: "companyName",
                                    accessor: ({ companyName }) => {
                                        return companyName;
                                    },
                                    disableSortBy: true,
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "First Name",
                                    id: "firstName",
                                    // eslint-disable-next-line react/display-name, react/prop-types
                                    accessor: ({ firstName }) => {
                                        return firstName;
                                    },
                                    disableSortBy: true,
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "Last Name",
                                    id: "lastName",
                                    // eslint-disable-next-line react/display-name, react/prop-types
                                    accessor: ({ lastName }) => {
                                        return lastName;
                                    },
                                    disableSortBy: true,
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "City",
                                    id: "city",
                                    // eslint-disable-next-line react/display-name, react/prop-types
                                    accessor: ({ city }) => {
                                        return city;
                                    },
                                    disableSortBy: true,
                                    className: "-cursor-pointer",
                                },
                            ]}
                        />
                        <ReactTableSimple
                            classes={classNames("-highlight", classes.table)}
                            data={workerDCTRansactionList}
                            loading={isDCTransactionLoading}
                            {...tableProps}
                            columns={[
                                {
                                    Header: "Job Id",
                                    id: "jobId",
                                    accessor: ({ payment }) => payment,
                                    className: "-cursor-pointer",
                                    disableSortBy: true,
                                    Cell: ({ value, row: { original } }) => {
                                        if (value && value.position && value.position.jobId) {
                                            return <Link to={`/jobs/${value.position.jobId}`}>{value.position.jobId}</Link>;
                                        }
                                        return '';
                                    },
                                },
                                {
                                    Header: "Position Id",
                                    id: "id",
                                    disableSortBy: true,
                                    accessor: ({ payment }) => {
                                        if (payment && payment.position && payment.position.id) {
                                            return payment.position.id
                                        }
                                        return '';
                                    },
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "Transaction Id",
                                    id: "TransactionId",
                                    accessor: ({ TransactionId }) => TransactionId,
                                    disableSortBy: true,
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "Transaction Status",
                                    id: "TransactionStatusDescription",
                                    accessor: ({ TransactionStatusDescription }) => TransactionStatusDescription,
                                    disableSortBy: true,
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "Amount",
                                    id: "Amount",
                                    accessor: ({ Amount }) => formatMoney(Amount),
                                    disableSortBy: true,
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "Client Ref No",
                                    id: "ClientReferenceNumber",
                                    accessor: ({ ClientReferenceNumber }) => ClientReferenceNumber,
                                    disableSortBy: true,
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "Contact Name",
                                    id: "ContactName",
                                    disableSortBy: true,
                                    accessor: ({ ContactName }) => ContactName,
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "Dc Customer No",
                                    id: "DcbCustomerNumber",
                                    accessor: ({ DcbCustomerNumber }) => DcbCustomerNumber,
                                    disableSortBy: true,
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "Interac Status",
                                    id: "InteracStatusDescription",
                                    disableSortBy: true,
                                    accessor: ({ InteracStatusDescription }) => InteracStatusDescription,
                                    className: "-cursor-pointer",
                                },
                                {
                                    disableSortBy: true,
                                    Header: "Interac Ref No",
                                    id: "InteracReferenceNumber",
                                    accessor: ({ InteracReferenceNumber }) => InteracReferenceNumber,
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "Date Of Funds",
                                    id: "DateOfFunds",
                                    disableSortBy: true,
                                    accessor: ({ DateOfFunds }) => _moment(DateOfFunds).format('DD-MM-YYYY HH:mm:ss'),
                                    className: "-cursor-pointer",
                                },
                                {
                                    Header: "Money Req Exp Date",
                                    disableSortBy: true,
                                    id: "MoneyReqExpDate",
                                    accessor: ({ MoneyReqExpDate }) => _moment(MoneyReqExpDate).format('DD-MM-YYYY HH:mm:ss'),
                                    className: "-cursor-pointer",
                                },
                            ]}
                        />
                    </SwipeableViews>
                </Grid>
                <Dialog
                    open={this.state.showSuspendWorker}
                    title={`Suspend ${fullName}?`}
                    description={`Select how long you’d like ${firstName} to be suspended for.`}
                    confirmText="Suspend"
                    onClose={this.hideSuspendWorker}
                    onConfirm={this.handleSuspendWorker}
                    onCancel={this.hideSuspendWorker}
                    alertType="warning"
                    icon="event_busy"
                    disabled={!this.state.suspensionReason.length}>
                    <TextField
                        select
                        style={{ marginTop: 36 }}
                        fullWidth
                        value={this.state.suspensionDuration}
                        onChange={this.onSuspensionChange}
                        margin="normal">
                        {SuspensionDurationOptions.map(({ label, value }) => (
                            <MenuItem key={`suspend-${value}`} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        multiline
                        label="Why? *"
                        inputProps={{
                            maxLength: 250,
                        }}
                        rows={5}
                        placeholder="Provide a reason for this suspension."
                        fullWidth
                        value={this.state.suspensionReason}
                        onChange={this.onSuspensionReasonChange}
                        margin="normal"
                        style={{ marginTop: 36 }}
                        helperText={`${250 - this.state.suspensionReason.length} character(s) left.`}
                    />
                </Dialog>
                <Dialog
                    open={this.state.showRemoveSuspension}
                    title={`Remove ${fullName}'s suspension?`}
                    description={`${firstName} will be able to accept jobs and work again.`}
                    confirmText="Remove Suspension"
                    onClose={this.hideRemoveSuspension}
                    onConfirm={this.handleRemoveSuspension}
                    onCancel={this.hideRemoveSuspension}
                    alertType="question"
                    icon="event_available"
                    disabled={!this.state.unsuspensionReason.length}>
                    <TextField
                        multiline
                        label="Why? *"
                        inputProps={{
                            maxLength: 250,
                        }}
                        rows={5}
                        placeholder="Provide a reason."
                        fullWidth
                        value={this.state.unsuspensionReason}
                        onChange={this.onUnsuspensionReasonChange}
                        margin="normal"
                        style={{ marginTop: 36 }}
                        helperText={`${250 - this.state.unsuspensionReason.length} character(s) left.`}
                    />
                </Dialog>
                <Dialog
                    open={this.state.showVerifyWorker}
                    title={`Verify ${fullName}?`}
                    description={`You are about to confirm that all of ${firstName}'s information is correct. They will be able to accept jobs.`}
                    confirmText="Verify"
                    onClose={this.hideVerifyWorker}
                    onConfirm={this.handleVerifyWorker}
                    onCancel={this.hideVerifyWorker}
                    alertType="question"
                    icon="verified_user"
                />
                <Dialog
                    open={this.state.showUnverifyWorker}
                    title={`Unverify ${fullName}?`}
                    description={`${firstName} will have to resubmit their information before they can work again.`}
                    confirmText="Unverify"
                    onClose={this.hideUnverifyWorker}
                    onConfirm={this.handleUnverifyWorker}
                    onCancel={this.hideUnverifyWorker}
                    alertType="warning"
                    icon="security"
                    disabled={!this.state.unverifyWorkerReason.length}>
                    <TextField
                        multiline
                        label="Why? *"
                        inputProps={{
                            maxLength: 250,
                        }}
                        rows={5}
                        placeholder="Provide a reason."
                        fullWidth
                        value={this.state.unverifyWorkerReason}
                        onChange={this.onUnverifyWorkerReasonChange}
                        margin="normal"
                        style={{ marginTop: 36 }}
                        helperText={`${250 - this.state.unverifyWorkerReason.length} character(s) left.`}
                    />
                </Dialog>
                <Dialog
                    open={this.state.showActivateWorker}
                    title={`Activate ${fullName}?`}
                    description={`This will allow ${firstName} to start accepting jobs.`}
                    confirmText="Activate"
                    onClose={this.hideActivateWorker}
                    onConfirm={this.handleActivateWorker}
                    onCancel={this.hideActivateWorker}
                    alertType="question"
                    icon="check_circle"
                    disabled={!this.state.activateWorkerReason.length}>
                    <TextField
                        multiline
                        label="Why? *"
                        inputProps={{
                            maxLength: 250,
                        }}
                        rows={5}
                        placeholder="Provide a reason."
                        fullWidth
                        value={this.state.activateWorkerReason}
                        onChange={this.onActivateReasonChange}
                        margin="normal"
                        style={{ marginTop: 36 }}
                        helperText={`${250 - this.state.activateWorkerReason.length} character(s) left.`}
                    />
                </Dialog>
                <Dialog
                    open={this.state.showDeactivateWorker}
                    title={`Deactivate ${fullName}?`}
                    description={`${firstName} will no longer be able to take jobs. They will not be notified.`}
                    confirmText="Deactivate"
                    onClose={this.hideDeactivateWorker}
                    onConfirm={this.handleDeactivateWorker}
                    onCancel={this.hideDeactivateWorker}
                    alertType="warning"
                    icon="cancel"
                    disabled={!this.state.deactivateWorkerReason.length}>
                    <TextField
                        multiline
                        label="Why? *"
                        name="deactivateWorkerReason"
                        inputProps={{
                            maxLength: 250,
                        }}
                        rows={5}
                        placeholder="Provide a reason."
                        fullWidth
                        value={this.state.deactivateWorkerReason}
                        onChange={this.onDeactivateReasonChange}
                        margin="normal"
                        style={{ marginTop: 36 }}
                        helperText={`${250 - this.state.deactivateWorkerReason.length} character(s) left.`}
                    />
                </Dialog>
                <Dialog
                    open={this.state.showPreferWorker}
                    title={`Prefer ${fullName}?`}
                    description={`Which employer’s preferred list would you like to add ${fullName} to?`}
                    confirmText="Prefer"
                    disabled={this.state.employerSelected === null}
                    onClose={this.hidePreferWorker}
                    onConfirm={this.handlePreferWorker}
                    onCancel={this.hidePreferWorker}
                    alertType="question"
                    icon="person_add">
                    {this.renderEmployerSelected()}
                </Dialog>
                <Dialog
                    open={this.state.showBanWorker}
                    title={`Ban ${fullName}?`}
                    description={`${fullName} will be added to the ban list for the employer you select below.`}
                    confirmText="Ban"
                    disabled={this.state.employerSelected === null || this.state.banWorkerReason === "NA"}
                    onClose={this.hideBanWorker}
                    onConfirm={this.handleBanWorker}
                    onCancel={this.hideBanWorker}
                    alertType="warning"
                    icon="report">
                    {this.renderEmployerSelected()}
                    {this.state.employerSelected && (
                        <TextField
                            select
                            fullWidth
                            value={this.state.banWorkerReason}
                            onChange={this.onBanWorkerReasonChange}
                            margin="normal"
                            style={{ marginTop: 36 }}>
                            <MenuItem value="NA">Select a reason...</MenuItem>
                            {Object.keys(BanReasonsLabel).map(key => (
                                <MenuItem key={key} value={BanReasonsLabel[key]}>
                                    {BanReasonsLabel[key]}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                </Dialog>
                {/* <Dialog
                    open={this.state.showPreferWorker}
                    title={`Prefer ${fullName}?`}
                    description={`Which employer’s preferred list would you like to add ${fullName} to?`}
                    confirmText="Prefer"
                    disabled={this.state.employerSelected === null}
                    onClose={this.hidePreferWorker}
                    onConfirm={this.handlePreferWorker}
                    onCancel={this.hidePreferWorker}
                    alertType="question"
                    icon="person_add">
                    {this.renderEmployerSelected()}
                </Dialog> */}
                <Dialog
                    open={this.state.showAddWorkerNote}
                    title={`Add a note to ${fullName}`}
                    description={`Add a freeform note to ${fullName}'s worker profile.`}
                    confirmText="Add Note"
                    disabled={!workerNote.length}
                    onClose={this.hideAddWorkerNote}
                    onConfirm={this.handleAddWorkerNote}
                    onCancel={this.hideAddWorkerNote}
                    icon="note_add"
                    fullWidth={true}
                    alertType="question">
                    <TextField
                        multiline
                        rows={6}
                        fullWidth={true}
                        value={workerNote}
                        onChange={this.onAddWorkerNoteChange}
                        margin="normal"
                        style={{ marginTop: 36 }}
                    />
                </Dialog>
                <Dialog
                    open={this.state.showAddTags}
                    title={`Add a tags to ${fullName}`}
                    confirmText="Add Tags"
                    disabled={workerTag.length <= 0}
                    onClose={this.hideAddTags}
                    onConfirm={this.handleAddTags}
                    onCancel={this.hideAddTags}
                    icon="local_offer"
                    maxWidth='sm'
                    size="sm"
                    alertType="question">
                    <TagsInput
                        selectedTags={this.onAddTagsChange}
                        fullWidth
                        variant="outlined"
                        id="tags"
                        name="tags"
                        placeholder="Add Tag"
                    />
                </Dialog>

                <Dialog
                    open={this.state.showDeleteAccount}
                    title={`Are you sure to delete account? `}
                    description={'Deleted account should not be recovered'}
                    confirmText="Delete"
                    onClose={this.hideDeleteAccount}
                    onConfirm={this.handleDeleteAccount}
                    onCancel={this.hideDeleteAccount}
                    alertType="warning"
                    icon="remove_circle"
                />
            </MainContent>
        );
    }
}

const mapState = state => {
    const { worker, positions, workerNotes, workerNote, workerTags, workerBannedFrom, workerDCTRansactionList } = WorkerDetailSelectors.getWorkerDetails(state);

    return {
        isLoading: WorkerDetailSelectors.getIsPageLoading(state),
        isDCTransactionLoading: WorkerDetailSelectors.getIsDCTransactionLoading(state),
        isApplyingAction: WorkerDetailSelectors.getIsApplyingAction(state),
        totalHoursWorked: worker ? worker.hoursWorkedCount : 0,
        positions: positions.data,
        positionsSort: positions.sort,
        workerNote: workerNote,
        workerNotes: workerNotes.data,
        positionsFilter: positions.filter,
        positionsPaginationData: WorkerDetailSelectors.getPositionsPaginationData(state),
        workerNotesPaginationData: state.workerDetails.pagination_WorkerNotesPageInfo,
        workerBannedFromPaginationData: state.workerDetails.pagination_WorkerBannedFromPageInfo,
        workerBannedFrom: workerBannedFrom.data,
        workerTag: workerTags,
        ...worker,
        worker,
        workerDCTRansactionList
    };
};

const mapActions = {
    fetchWorker: WorkerDetailActions.fetchWorker,
    loadWorkerNotes: WorkerDetailActions.loadWorkerNotes,
    onPageChangePositions: WorkerDetailActions.fetchPositionsList,
    onSortedChangePositions: WorkerDetailActions.setPositionListSort,
    onFilteredChangePositions: WorkerDetailActions.setPositionListFilter,
    onPageChangeWorkerNotes: WorkerDetailActions.fetchWorkerNotes,
    fetchWorkerBannedFrom: (e, workerId) => WorkerDetailActions.fetchWorkerBannedFrom(e, workerId),
    onSuspendWorker: WorkerDetailActions.suspendWorker,
    onRemoveWorkerSuspension: WorkerDetailActions.unsuspendWorker,
    onVerifyWorker: WorkerDetailActions.verifyWorker,
    onUnverifyWorker: WorkerDetailActions.unverifyWorker,
    onActivateWorker: WorkerDetailActions.activateWorker,
    onDeactivateWorker: WorkerDetailActions.deactivateWorker,
    onPreferWorker: WorkerDetailActions.preferWorker,
    onBanWorker: WorkerDetailActions.banWorker,
    onAddWorkerNote: WorkerDetailActions.saveWorkerNote,
    updateWorkerNote: WorkerDetailActions.updateWorkerNote,
    resetWorkerNote: WorkerDetailActions.resetWorkerNote,
    onAddWorkerTags: WorkerDetailActions.saveWorkerTags,
    onDeleteWorkerAccount: WorkerDetailActions.onDeleteWorkerAccount,
    updateWorkerTags: WorkerDetailActions.updateWorkerTags,
    resetWorkerTags: WorkerDetailActions.resetWorkerTags,
    removeWorkerTags: WorkerDetailActions.removeWorkerTags,
    changeFilterTags: WorkerActions.changeFilter,
    setUpdateWorkerData: WorkerDetailActions.setUpdateWorkerData,
    clearWorkerUpdateData: WorkerDetailActions.clearWorkerUpdateData,
    updateWorkerProfile: WorkerDetailActions.updateWorkerProfile,
    findWorkerEtransfers: WorkerDetailActions.findWorkerEtransfers,
    createAndSendPaystub: FinanceActions.createAndSendPaystub,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchWorker, loadWorkerNotes, fetchWorkerBannedFrom } = dispatchProps;
    return {
        ...stateProps,
        ...dispatchProps,
        fetchWorker: () => fetchWorker(ownProps.match.params.id),
        loadWorkerNotes: () => loadWorkerNotes(ownProps.match.params.id),
        loadWorkerBannedFrom: () => fetchWorkerBannedFrom(0, ownProps.match.params.id),
        ...ownProps,
    };
};

export default connect(mapState, mapActions, mergeProps)(withStyles(styles)(WorkerDetail));
